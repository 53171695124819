<template>
  <div class="customer-list">
    <pagoda-form-table-layout>
      <!-- 表单 -->
      <template slot="form">
        <el-form class="el-form-reset" inline>
          <el-form-item label="标题" prop="title">
            <el-input
              placeholder="请输入标题"
              v-model="formData.title"
            ></el-input>
          </el-form-item>
          <el-form-item label="分组" prop="group_id">
            <group-select
              v-model="formData.group_id"
              @change="handleGroupId"
              type="material"
              ref="group"
            ></group-select>
          </el-form-item>
          <!-- 表单操作按钮 -->
          <div class="pagoda-button-group">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </div>
        </el-form>
        <el-button type="primary" @click="handleAddMaterial(1)">
          添加素材
        </el-button>
      </template>
      <template slot="before-table">
        <el-tabs
          class="pagoda-form-table-layout-tab"
          @tab-click="handleTabChange"
          v-model="currentTab"
        >
          <el-tab-pane
            :label="item.type"
            :name="`${item.id}`"
            v-for="item in typeList"
            :key="item.id"
          ></el-tab-pane>
        </el-tabs>
      </template>
      <!-- 实现一屏展示时 一定要配置表格高度 -->
      <template slot="table" slot-scope="scope">
        <el-table
          ref="table"
          :data="tableData"
          :height="scope.height"
          v-loading="isTableLoading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="content" label="内容" min-width="200">
            <template slot-scope="scope">
              <el-tooltip
                class="table-item-content"
                effect="dark"
                placement="top"
              >
                <pre slot="content" class="pre-content">{{
                  scope.row.content
                }}</pre>
                <span class="content">{{ scope.row.content }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型"></el-table-column>
          <el-table-column prop="group_name" label="内容分组"></el-table-column>
          <el-table-column
            prop="create_time"
            width="150"
            label="创建时间"
          ></el-table-column>
          <el-table-column prop="username" label="操作人"></el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleAddMaterial(2, scope.row.id)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                @click="handleDeleteMaterial(scope.row.id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <template slot="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :current-page="pageNumber"
          :total="totalDataNum"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </template>
    </pagoda-form-table-layout>
  </div>
</template>
<script>
import groupSelect from '@/components/group-select'
import http from '@/services/api/reach'
export default {
  data() {
    return {
      formData: {
        page: 1,
        page_size: 10
      },
      tableData: [],
      currentTab: '',
      typeList: [],
      customerStatusList: [],
      companyTags: [],
      totalDataNum: 0,
      customerNum: 0,
      pageSize: 10,
      pageNumber: 1,
      isTableLoading: false
    }
  },
  components: {
    groupSelect
  },
  methods: {
    handleSearch() {
      this.pageNumber = 1
      this.formData.page = '1'
      this.handleSubmit()
    },
    handleSubmit() {
      this.formData.type = 'material'
      const params = this.formData
      http
        .getMaterialList(params)
        .then((res) => {
          this.tableData = res.data.list || []
          this.totalDataNum = res.data.count || 0
        })
        .catch(() => {})
    },
    handleReset() {
      // 重置后，重新初始化门店代码下拉框数据
      this.$refs['group'].clearHandle()
      this.formData = {
        page: 1,
        page_size: 10
      }
      this.pageNumber = 1
      this.pageSize = 10
      this.handleSubmit()
    },
    getMaterialType() {
      const params = { type: 'material' }
      http
        .getMaterialType(params)
        .then((res) => {
          // 目前只需要图文这一个类型
          const list = res.data.slice(0, 1)
          let data =
            res.data.filter((element) => {
              return ['图文', '文本'].includes(element.category_name)
            }) || []
          console.log('data', data)
          this.$store.commit('MATERIAL_TYPE', data)
          this.typeList = res.data.slice(0, 1) || []
          this.typeList.unshift({
            type: '全部',
            id: ''
          })
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    handleTabChange(obj) {
      this.formData.category = obj.name
      this.handleSubmit()
    },
    handleGroupId(id) {
      this.formData.group_id = id
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.handleSubmit()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.handleSubmit()
    },
    // 至编辑页
    handleAddMaterial(type, id) {
      // type为1是添加素材/话术，2是编辑
      this.$router.push({
        path: '/guideMaterialStoreEdit',
        query: {
          type,
          id
        }
      })
    },
    deleteMateria(id) {
      const params = { id }
      http
        .deleteMateria(params)
        .then((res) => {
          this.handleSubmit()
        })
        .catch(() => {})
    },
    handleDeleteMaterial(id) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteMateria(id)
        })
        .catch(() => {})
    }
  },
  created() {
    this.getMaterialType()
    this.handleSubmit()
  },
  activated() {
    // 刷新列表数据
    this.refreshListData()
    this.$nextTick(() => {
      // 切换页面后表格偏移问题，对table进行重新布局
      this.$refs['table'].doLayout()
    })
  }
}
</script>

<style lang="less">
.customer-list {
  height: 100%;
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 220px;
  }
  .el-table {
    height: 90% !important;
  }
}

.table-item-content {
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .pre-content {
    white-space: pre-wrap;
  }
  .content {
  }
}

/deep/ .el-tooltip__popper {
  max-width: 300px;
}
</style>
